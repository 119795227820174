/* eslint-disable */
import React from "react"
import {
  FormattedDate,
  FormattedMessage,
} from "@pixicommerce/module-components"

const CopyRight = () => {
  let date = new Date().getFullYear()

  return (
    <div className="copyright-text">
      © <FormattedDate value={new Date()} year="numeric" />{" "}
      <FormattedMessage
        id={"footer.copyrightText"}
        defaultMessage={`Pixielity Co. All Rights Reserved.`}
      />
    </div>
  )
}

export default CopyRight
