/* eslint-disable */
import React from "react"
import CopyRight from "./CopyRight"
import { Logo } from "@pixicommerce/module-components"
import { FormattedMessage, Container } from "@pixicommerce/module-components"

import { pixielityIcon } from "assets/images"

import "./footer.scss"

const Footer = () => {
  return (
    <footer className="page-footer">
      <Container>
        <div className="footer-logo">
          <Logo lightLogo={pixielityIcon} darkLogo={pixielityIcon} />
        </div>
        <div className="footer-content">
          <FormattedMessage
            id={"footer.content"}
            defaultMessage={
              "Pixielity is a US-based internet services company."
            }
          />
        </div>
        <div className="footer-copyright">
          <CopyRight />
        </div>
      </Container>
    </footer>
  )
}

export default Footer
