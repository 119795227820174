const flatten = require("flat")

const footer = require("./footer")
const common = require("./common")
const landing = require("./landing")

const messages = {
  footer,
  common,
  landing,
}

module.exports = flatten(messages)
