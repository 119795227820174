module.exports = {
  // NOTE: Toggle Style
  color: {
    white: "#ffffff",
    primary: "#374999",
    secondary: "#1C2B46",
  },
  toggle: {
    size: "small",
    color: "#000",
  },
  socialIcons: {
    round: false,
    iconColor: "#000",
    backgroundColor: "#fff",
  },
  skeleton: {
    color: "#f8edeb",
    highlightColor: "#fcf7f7",
  },
}
