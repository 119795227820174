/* eslint-disable */
import React from "react"
import PropTypes from "prop-types"
import Headroom from "react-headroom"

import PageContext from "layouts/PageContext"
import Header from "components/SingleInstance/Header"
import Footer from "components/SingleInstance/Footer"

const Layout = props => {
  const pageContextValue = {
    custom: props.custom,
    page: { ...props.pageContext, originalPath: props.path },
  }

  return (
    <PageContext.Provider value={pageContextValue}>
      <div className="page-wrapper landingpage-wrapper">
        <Headroom upTolerance={10} downTolerance={10} style={{ zIndex: "20" }}>
          <Header {...props} />
        </Headroom>
        <main className="page-main">{props.children}</main>
        <Footer />
      </div>
    </PageContext.Provider>
  )
}

Layout.propTypes = {
  custom: PropTypes.any,
  value: PropTypes.any,
  location: PropTypes.object,
  crumbLabel: PropTypes.string,
  pageContext: PropTypes.object,
  children: PropTypes.node.isRequired,
}

export default Layout
