import React from "react"
import {
  Head,
  Toaster,
  Transition,
  ErrorBoundary,
} from "@pixicommerce/module-components"
import { IntlProvider } from "react-intl"
import { languages } from "@pixicommerce/module-locale"
import { getLocale, getLocaleCode } from "@pixicommerce/module-helpers"

import Layout from "layouts"
import translations from "locale/translations"

export default ({ element, props }) => {
  const locale = getLocale()
  const localeCode = getLocaleCode()
  const defaultLocale = languages.find(language => language.default).locale

  return (
    <ErrorBoundary>
      <Head />
      <IntlProvider
        key={locale}
        locale={locale}
        defaultLocale={defaultLocale}
        messages={translations[localeCode]}
      >
        <Transition location={props.location}>
          <Layout {...props}>{element}</Layout>
        </Transition>
        <Toaster />
      </IntlProvider>
    </ErrorBoundary>
  )
}
