/* eslint-disable */
import React from "react"
import { useDispatch } from "react-redux"
import { Logo, Container, Button } from "@pixicommerce/module-components"
import { navigate, getLocale } from "@pixicommerce/module-helpers"
import BrowserDatabase from "@pixicommerce/module-browser-database"
import { getLanguages, languages } from "@pixicommerce/module-locale"
import { setLocale } from "@pixicommerce/module-components/src/store/actions"

import PageContext from "layouts/PageContext"

import { pixielityLogo } from "assets/images"

import "./header.scss"

const LanguageSwitcher = () => {
  const pageContexts = React.useContext(PageContext)
  const locale = getLocale()
  const [language, setLanguage] = React.useState({})
  const dispatch = useDispatch()

  React.useEffect(() => {
    const lang = getLanguages(languages).find(lang => {
      if (locale && lang.locale !== locale) {
        return true
      } else if (!lang.default) {
        return true
      }
    })
    setLanguage(lang)
  }, [languages && languages.length])

  const handleLangChange = language => {
    const lang = language.locale
    const direction = language.direction
    const urlPrefix = language.urlPrefix
    const localeCode = language.localeCode
    const defaultLang = language.ogLanguage
    const queryPrefix = language.queryPrefix
    const seoHreflang = language.seoHreflang
    const originalPath = pageContexts.page
      ? pageContexts.page.originalPath
      : "/"

    if (!!originalPath) {
      const newPathname = `${originalPath}`
      dispatch(setLocale(lang))
      BrowserDatabase.setItem(lang, "locale", null, true)
      BrowserDatabase.setItem(locale, "getLocale", null, true)
      BrowserDatabase.setItem(urlPrefix, "urlPrefix", null, true)
      BrowserDatabase.setItem(direction, "direction", null, true)
      BrowserDatabase.setItem(localeCode, "localeCode", null, true)
      BrowserDatabase.setItem(queryPrefix, "queryPrefix", null, true)
      BrowserDatabase.setItem(defaultLang, "defaultLang", null, true)
      BrowserDatabase.setItem(seoHreflang, "seoHreflang", null, true)

      navigate(newPathname)
    }
  }

  return (
    <header className="page-header">
      <Container>
        <div className="header-content">
          <div className="header-logo">
            <Logo lightLogo={pixielityLogo} darkLogo={pixielityLogo} />
          </div>
          <div className="lang-btn">
            {/* <LanguageSwitcher storeViews={languages} /> */}
            <Button
              style={{ cursor: "pointer" }}
              onClick={() => handleLangChange(language)}
            >
              {language.label}
            </Button>
          </div>
        </div>
      </Container>
    </header>
  )
}

export default LanguageSwitcher
