const apiVersion = `V2`
const baseUrl = `https://pixicommerce-cors.herokuapp.com/https://m2.shopatculture.com/rest`

// NOTE: Analytics
const analytics = {
  hotJar: {
    includeInDevelopment: true, // NOTE: optional parameter to include script in development
    id: "YOUR_HOTJAR_ID",
    sv: "YOUR_HOTJAR_SNIPPET_VERSION",
  },
  tagManager: {
    // NOTE: You can add multiple tracking ids and a pageview event will be fired for all of them.
    trackingIds: [
      "GA-TRACKING_ID", // NOTE: Google Analytics / GA
      "AW-CONVERSION_ID", // NOTE: Google Ads / Adwords / AW
      "DC-FLOODIGHT_ID", // NOTE: Marketing Platform advertising products (Display & Video 360, Search Ads 360, and Campaign Manager)
    ],
    // NOTE: This object gets passed directly to the gtag config command
    // NOTE: This config will be shared across all trackingIds
    gtagConfig: {
      optimize_id: "OPT_CONTAINER_ID",
      anonymize_ip: true,
      cookie_expires: 0,
      send_page_view: true,
      currency: "SAR",
      country: "SA",
    },
    // NOTE: This object is used for configuration specific to this plugin
    pluginConfig: {
      // NOTE: Puts tracking script in the head instead of the body
      head: false,
      // NOTE: Setting this parameter is also optional
      respectDNT: true,
      // NOTE: Avoids sending pageview hits from custom paths
      exclude: ["/preview/**", "/do-not-track/me/too/"],
    },
  },
  facebookPixel: {
    pixelId: "pixel id here",
    trackViewContent: true, // NOTE: skip track ViewContent on route update
  },
  googleAnalytics: {
    // The property ID; the tracking code won't be generated without it
    trackingId: "YOUR_GOOGLE_ANALYTICS_TRACKING_ID",
    // Defines where to place the tracking script - `true` in the head and `false` in the body
    head: false,
    // Setting this parameter is optional
    anonymize: true,
    // Setting this parameter is also optional
    respectDNT: true,
    // Avoids sending pageview hits from custom paths
    exclude: ["/preview/**", "/do-not-track/me/too/"],
    // Delays sending pageview hits on route update (in milliseconds)
    pageTransitionDelay: 0,
    // Enables Google Optimize using your container Id
    optimizeId: "YOUR_GOOGLE_OPTIMIZE_TRACKING_ID",
    // Enables Google Optimize Experiment ID
    experimentId: "YOUR_GOOGLE_EXPERIMENT_ID",
    // Set Variation ID. 0 for original 1,2,3....
    variationId: "YOUR_GOOGLE_OPTIMIZE_VARIATION_ID",
    // Defers execution of google analytics script after page load
    defer: false,
    // Any additional optional fields
    sampleRate: 5,
    siteSpeedSampleRate: 10,
    cookieDomain: "example.com",
    // defaults to false
    enableWebVitalsTracking: true,
  },
}

// NOTE: Chat Intgrations
const chatWidget = {
  intercom: {
    app_id: "YOUR_INTERCOM_APP_ID",
    include_in_development: true,
    delay_timeout: 5,
  },

  zendesk: {
    appId: "your-zendesk-app-id",
    enableDuringDevelop: true, // NOTE: Optional. Disables Zendesk chat widget when running Gatsby dev server. Defaults to true.
    zESettings: {}, // NOTE: zESettings object on page load: https://developer.zendesk.com/embeddables/docs/widget/chat
  },
}

// NOTE: Tracking Intgrations
const issueTracking = {
  // NOTE: Sentry Options
  sentry: {
    environment: "production",
    dsn: "https://9c6cb66c5c14442993dc44750b8dbf8a@o484900.ingest.sentry.io/5610570",
  },
}

// NOTE: Google Settings
const googleFirebase = {
  apiKey: "AIzaSyAZe8uzmhkwtSSd02_Gd1fcmCwAMn1yk_g",
  authDomain: "youstores-61a72.firebaseapp.com",
  databaseURL: "https://youstores-61a72.firebaseio.com",
  projectId: "youstores-61a72",
  storageBucket: "youstores-61a72.appspot.com",
  messagingSenderId: "848374501944",
  appId: "1:848374501944:web:09b9d82e5e6981c4ca4ccd",
  measurementId: "G-XR9BHBNELG",
}

const googleDynamicLink = {
  appStoreId: "1234456",
  dynamicLink: "pixicommerce.com",
  dynamicDomain: "pub.4youstores.com",
  androidPackageName: "com.fayiz.foryou",
  IOSPackageName: "com.pixicommerce.Culture",
}

const googleMaps = {
  address: "Riyadh, SA",
  apiKey: "AIzaSyDAdV-E1mzcgB5e7LG_xPa1Itdxq9xkimM",
}

const googleGeoLocation = {
  apiKey: "AIzaSyDAdV-E1mzcgB5e7LG_xPa1Itdxq9xkimM",
}

const verification = {
  brave: {
    token: "abcdefghijklmnopqrstuvwxyzABCDEFGHIJKLMNOPQRSTUVWXYZ1234567890",
    domain: "test.dev",
  },
  google: {
    key: "QlRmuLQWttdkbKlZ0ZwIBX3xv0M8ouqTW3wE2Eg_jKI",
  },
}

module.exports = {
  baseUrl,
  analytics,
  chatWidget,
  googleMaps,
  apiVersion,
  verification,
  issueTracking,
  googleFirebase,
  googleDynamicLink,
  googleGeoLocation,
}
