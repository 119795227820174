import * as Sentry from "@sentry/react"
import config from "@pixicommerce/module-config"
import { log } from "@pixicommerce/module-helpers"

import wrapWithElement from "./wrap-with-element"
import wrapWithProvider from "./wrap-with-provider"

import "./src/assets/sass/globals.scss"

const { Integrations } = require("@sentry/tracing")

const sentryOptions = config.issueTracking.sentry
const fbqOptions = config.analytics.facebookPixel
const fbq = typeof window !== `undefined` ? window.fbq : () => {}

export const onClientEntry = () => {
  // Note: Init Sentry
  Sentry.init({
    sentryOptions,
    integrations: [new Integrations.BrowserTracing()],
  })

  process.env.NODE_ENV === "development" &&
    log("Pixicommerce has Started 🎉", "custom")
}

export const onRouteUpdate = ({ location, prevLocation }) => {
  // Note: Don't track while developing.
  if (process.env.NODE_ENV === `production` && typeof fbq === `function`) {
    fbqOptions.trackViewContent &&
      fbq("trackCustom", `viewPage`, location.pathname + location.search)
  }
}

export const wrapPageElement = wrapWithElement
export const wrapRootElement = wrapWithProvider
