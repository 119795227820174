let date = new Date().getFullYear()
const { color } = require("./theme")
const { Slide, Zoom, Flip, Bounce } = require("react-toastify")

// NOTE: Base Data
const store = {
  pathPrefix: "",
  currency: "SAR",
  name: "Pixielity",
  deliveryCharges: 2,
  phone: "966597014780",
  domain: "pixielity.com",
  DefaultCountryCode: "SA",
  accountPrefix: "/account",
  helpEmail: "care@pixielity.com",
  url: "https://dev.pixielity.com",
  logo: "src/assets/images/logo.svg",
  title: "Product Design & Development Agency - Pixielity",
  description: "Product Design & Development Agency",
}

// NOTE: Blog Settings
const blog = {
  pathPrefix: "/blog",
}

// NOTE: SOCIAL PROFILES
const socialLinks = {
  facebook: {
    enabled: true,
    username: "pixielity",
    url: "https://facebook.com/pixielity",
  },
  twitter: {
    enabled: true,
    username: "pixielity",
    url: "https://twitter.com/pixielity",
  },
  instagram: {
    enabled: true,
    username: "pixielity",
    url: "https://instagram.com/pixielity",
  },
  snapchat: {
    enabled: true,
    username: "pixielity",
    url: "https://snapchat.com/pixielity",
  },
  linkedin: {
    enabled: true,
    username: "pixielity",
    url: "https://linkedin.com/pixielity",
  },
}

// NOTE: Download App Settings
const downloadApp = {
  googlePlayStoreUrl:
    "https://play.google.com/store/apps/details?id=com.noon.buyerapp&hl=ar&gl=US",
  appStoreUrl:
    "https://apps.apple.com/us/app/noon-shopping-%D9%86%D9%88%D9%86-%D9%84%D9%84%D8%AA%D8%B3%D9%88%D9%82/id1269038866",
}

// NOTE: Author Data
const author = {
  name: "Pixicommerce",
  twitterAccount: "pixicommerce",
  email: "pixicommerce@gmail.com",
  copyright: `© ${date} Pixicommerce. All rights reserved`,
}

const gatsbyOptions = {
  // NOTE: Manifest Options
  manifest: {
    lang: "en",
    start_url: "/",
    name: store.name,
    display: "standalone",
    include_favicon: true,
    short_name: store.name,
    cache_busting_mode: "none",
    theme_color: color.primary,
    background_color: color.white,
    // NOTE: max 12 characters
    icon: "src/assets/images/icon.png",
    description: store.description,
    localize: [
      {
        lang: "ar",
        name: "بيكسي كومارس",
        start_url: "/ar-saudi",
        short_name: "بيكسي كومارس",
        description: store.description,
      },
    ],
  },

  // NOTE: Offline Mode Options
  offlineMode: {
    precachePages: [`/blog/*`, `/faqs/*`, `no-internet`],
    workboxConfig: {
      maximumFileSizeToCacheInBytes: 6000000,
      runtimeCaching: [
        {
          // NOTE: Use cacheFirst since these don't need to be revalidated (same RegExp
          // NOTE: and same reason as above)
          urlPattern: /(\.js$|\.css$|[^:]static\/)/,
          handler: "CacheFirst",
        },
        {
          // NOTE: page-data.json files, static query results and app-data.json
          // NOTE: are not content hashed
          urlPattern: /^https?:.*\/page-data\/.*\.json/,
          handler: "StaleWhileRevalidate",
        },
        {
          // NOTE: Add runtime caching of various other page resources
          urlPattern:
            /^https?:.*\.(png|jpg|jpeg|webp|svg|gif|tiff|js|woff|woff2|json|css)$/,
          handler: "StaleWhileRevalidate",
        },
        {
          // NOTE: Google Fonts CSS (doesn't end in .css so we need to specify it)
          urlPattern: /^https?:\/\/fonts\.googleapis\.com\/css/,
          handler: "StaleWhileRevalidate",
        },
      ],
    },
  },

  salScroll: {
    once: true, // NOTE: Defines if animation needs to be launched once
    threshold: 1, // NOTE: Percentage of an element's area that needs to be visible to launch animation
    disable: false, // NOTE: Flag for disabling animations

    // NOTE: Advanced Options
    rootMargin: "0% 50%", // NOTE: Corresponds to root's bounding box margin
    selector: "[data-sal]", // NOTE: Selector of the elements to be animated
    enterEventName: "sal:in", // NOTE: Enter event name
    exitEventName: "sal:out", // NOTE: Exit event name
    animateClassName: "sal-animate", // NOTE: Class name which triggers animation
    disabledClassName: "sal-disabled", // NOTE: Class name which defines the disabled state
  },

  minify: {
    debug: true, // NOTE: debug optional, default false
    config: {
      // NOTE: Enabled default by this plugin
      minifyJS: true,
      minifyCSS: true,
      // NOTE: Disabled default by html-minifier-terser
      sortAttributes: true,
      useShortDoctype: true,
      removeComments: false,
      collapseWhitespace: false,
      removeScriptTypeAttributes: false,
      removeStyleLinkTypeAttributes: false,
    },
  },

  pageProgress: {
    height: 3,
    footerHeight: 500,
    excludePaths: ["/"],
    color: color.primary,
    prependToBody: false,
    includePaths: [{ regex: `^/${blog.pathPrefix}` }],
  },
}

// NOTE: e-Commerce Setting
const features = {
  totals: {
    isTaxAvailable: false,
    isTotalAvailable: false,
    isShippingAvailable: false,
  },
  LoaderType: "grow",
  brandSearch: false,
  scrollToTop: false,
  dataCountPerPage: 8,
  allowGuestCart: true,
  imageBlurEffect: "blur",
  allowGuestWishlist: true,
  infiniteScrolling: false,
  resendVerificationTimer: 60,
  order: {
    showChangeAddress: false,
  },
}

const NProgress = {
  // Setting a color is optional.
  color: color.primary,
  // Disable the loading spinner.
  showSpinner: true,
}

// NOTE: Instagram Feed
const feed = {
  instagram: {
    userName: socialLinks.instagram.username,
  },
  sitemap: {
    fileName: "sitemap.xml",
    changefreq: "daily",
  },
}

// NOTE: Slider Options
const sliderOptions = {
  dots: true,
  speed: 300,
  arrows: false,
  infinite: true,
  autoplay: true,
  slidesToShow: 3,
  slidesToScroll: 1,
  autoplaySpeed: 2000,
  responsive: [
    {
      breakpoint: 1024,
      settings: {
        infinite: true,
        slidesToShow: 3,
        slidesToScroll: 3,
      },
    },
    {
      breakpoint: 991,
      settings: {
        dots: true,
        slidesToShow: 2,
        slidesToScroll: 2,
      },
    },
    {
      breakpoint: 768,
      settings: {
        dots: true,
        slidesToShow: 1,
        slidesToScroll: 1,
      },
    },
  ],
}

// NOTE: Toast Options
const toastOptions = {
  autoClose: 5000,
  draggable: true,
  newestOnTop: true,
  transition: Slide,
  closeButton: true,
  closeOnClick: true,
  pauseOnHover: true,
  position: "top-right",
  hideProgressBar: true,
  pauseOnFocusLoss: true,
  progressClassName: "h-0",
  toastClassName:
    "bg-primary text-white text-center px-2 py-3 shadow-none rounded-lg",
}

module.exports = {
  feed,
  blog,
  store,
  author,
  features,
  NProgress,
  socialLinks,
  downloadApp,
  toastOptions,
  sliderOptions,
  gatsbyOptions,
}
